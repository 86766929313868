import React, { useCallback, useEffect, useState } from "react";
import { deletePlayer, getAllPlayers } from "../../utils/server/serverAPI";
import { Player, PlayerId } from "../../state/state";
import { Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import Column from "antd/es/table/Column";


export function AdminListPlayers({ loadPlayers, players }) {
  useEffect(loadPlayers, [loadPlayers]);

  const handleClick = useCallback((playerId: PlayerId) => {
    async function doIt() {
      await deletePlayer(playerId);
      await loadPlayers();
    }
    doIt();
  }, [loadPlayers])

  const playerRows = players.map(({ playerId, fetlife }) => {
    return {
      playerId,
      fetlifeUsername: fetlife.username,
      fetlifeId: fetlife.id
    }
  })

  return (
    <div>
      <Table dataSource={playerRows} >
        <Column title="Player Id" dataIndex={"playerId"} key="playerId" />
        <Column title="Fetlife Username" dataIndex={"fetlifeUsername"} key="fetlifeUsername" />
        <Column title="Fetlife Id" dataIndex={"fetlifeId"} key="fetlifeId" />
        <Column
          title="Fetlife Id"
          dataIndex={"fetlifeId"}
          key="fetlifeId"
          render={(_: any, record: { playerId: PlayerId; }) => {
            if (record.playerId === "admin") {
              return null;
            }
            return (
              <DeleteOutlined
                style={{ cursor: "pointer" }}
                onClick={() => handleClick(record.playerId)}
              />
            )
          }}
        />
      </Table>
    </div>
  )
}