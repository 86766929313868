import React, { useCallback, useEffect, useState } from "react";
import "../../css/LeaveCommentPage.sass";
import { BaseCommentPage } from "./BaseCommentPage";
import { useGetCommentIdFromUrl } from "../../utils/hooks/useGetCommentIdFromUrl"
import { Comment, PlayerId } from "../../state/state";
import { getComment, updateComment } from "../../utils/server/serverAPI";
import { PlayerTag } from "../../common/PlayerTag";

export function EditCommentPage() {
  const commentId = useGetCommentIdFromUrl();
  const [comment, setComment] = useState<Comment | null>();

  useEffect(() => {
    async function doIt() {
      const myComment = await getComment(commentId)
      setComment(myComment);
    }
    doIt();
  }, [commentId, setComment])

  const handleSubmit = useCallback((text: string) => {
    if (text != null) {
      updateComment(commentId, text);
    }
  }, [commentId])

  const playerId = comment?.isAbout ?? "" as PlayerId;
  return (
    <BaseCommentPage
      onSubmit={handleSubmit}
      title={<div>Edit your comment about playing with <PlayerTag playerId={playerId} /></div>}
      initialText={comment?.comment}
      playerId={playerId}
    />
  )
}