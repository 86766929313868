import React, { useCallback } from "react";
import { Button, Form, Input } from "antd";
import "../../css/AdminCreatePlayer.sass";
import { createPlayer, getPlayerFromFlId } from "../../utils/server/serverAPI";

export function AdminCreatePlayer({ loadPlayers }) {

  const handleSubmit = useCallback(async ({ username, fetlife_id, password }) => {
    async function doIt() {
      const player = await getPlayerFromFlId(fetlife_id);

      if (player == null) {

        await createPlayer({
          username,
          id: fetlife_id,
          password: password
        })

        loadPlayers();

      } else {
        console.log("Cannot create user. User already exists.")
      }
    }
    doIt()
  }, [loadPlayers])

  return (
    <Form
      name="login-form"
      layout="vertical"
      style={{ width: 300 }}
      initialValues={{ remember: true }}
      onFinish={handleSubmit}
    >
      <Form.Item
        label="Fetlife Username"
        name="username"
        rules={[{ required: true, message: 'Fetlife username required' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Fetlife Id"
        name="fetlife_id"
        rules={[{ required: true, message: 'Fetlife ID Required' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: true, message: 'password required' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item label={null}>
        <Button htmlType="submit" className="signInButton" type="primary">
          Create
        </Button>
      </Form.Item>
    </Form>
  )
}