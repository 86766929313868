import React from "react";
import { Button } from "antd";
import { useGetIdFromPlayerUrl } from "../../utils/hooks/useGetIdFromPlayerUrl";
import { NavigationHooks } from "../../utils/hooks/navigationHooks";

export function LeaveCommentButton() {
  const playerId = useGetIdFromPlayerUrl();
  const navigateToLeaveCommentPage = NavigationHooks.useToLeaveCommentPage();

  const handleClick = () => {
    navigateToLeaveCommentPage(playerId);
  }

  return (
    <Button className="leave-comment-button" onClick={handleClick}>
      Leave a comment
    </Button>
  )
}