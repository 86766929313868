import React, { useCallback, useContext, useState } from "react";
import { Input, Button, List } from "antd";
import { PlayerTag } from "../../common/PlayerTag";
import "../../css/LeaveCommentPage.sass";
import { NavigationHooks } from "../../utils/hooks/navigationHooks";
import { PlayerId } from "../../state/state";
import { MercyContext } from "../../state/MercyContext";

const { TextArea } = Input;
const PROMPTS = [
  {
    title: "Setting the Scene",
    content: "Describe the environment where the kink play occurred. How did it influence the overall experience?",
  }, {
    title: "Emotional Journey",
    content: "What emotions did you feel before, during, and after the experience? How did they evolve?",
  }, {
    title: "Consent and Boundaries",
    content: "How did you communicate about consent and establish boundaries with your partner? Were there any surprises?",
  }, {
    title: "Favorite Moments",
    content: "What was the highlight of the experience for you? Why did it stand out?",
  }, {
    title: "Sensations and Reactions",
    content: "What physical sensations did you experience? How did they contribute to your enjoyment?",
  }, {
    title: "Aftercare Experience",
    content: "Describe the aftercare you engaged in. How did it help you process the experience emotionally and physically?",
  }, {
    title: "Connection with Partner",
    content: "How did this experience affect your connection with your partner? Did it bring you closer together?",
  }, {
    title: "Learning Outcomes",
    content: "What did you learn about yourself or your partner through this experience?",
  }, {
    title: "Safety Measures",
    content: "What safety practices did you implement, and how did they impact your sense of security during the play?",
  }, {
    title: "Overall Impression",
    content: "What is your overall impression of the kink experience? Would you want to explore this again in the future?",
  },
];


export function BaseCommentPage({ onSubmit, title, playerId, initialText }: { onSubmit: (k: string) => void; title: JSX.Element; playerId: PlayerId; initialText?: string; }) {
  const { dispatch } = useContext(MercyContext);
  const [text, setText] = useState<string | undefined>();
  const navigateToPlayerPage = NavigationHooks.useToPlayerPage();

  const handleChange = useCallback((evt) => {
    setText((evt.target as any).value);
  }, [setText])

  const handleBack = useCallback(() => {
    navigateToPlayerPage(playerId)
  }, [navigateToPlayerPage, playerId])

  const handleSubmit = useCallback(() => {
    if (text != null && text.length > 0) {
      onSubmit(text)
      dispatch({ type: "SET_SHOULD_RELOAD_COMMENTS", payload: true })
    }
    navigateToPlayerPage(playerId)
  }, [onSubmit, text, navigateToPlayerPage, playerId, dispatch])

  return (
    <div className="leave-comment-page">
      <div className="leave-comment-header">{title}</div>
      <TextArea
        className="leave-comment-text-area"
        value={text ?? initialText}
        onChange={handleChange}
      />
      <div>
        <Button className="back-button" onClick={handleBack} >
          Back
        </Button>
        <Button className="submit-button" onClick={handleSubmit} disabled={text == null || text?.length === 0}>
          Submit
        </Button>
      </div>
      <div>
        <List
          header={<div style={{ fontWeight: 700, fontSize: 17, marginTop: 10, textAlign: "center", display: "flex" }} >Don't know what to write? Here are some ideas to help you get started.</div>}
          dataSource={PROMPTS}
          renderItem={({ title, content }) => (
            <List.Item style={{ display: "flex", flexDirection: "row" }}>
              <List.Item.Meta
                title={title}
                description={content}
              />
            </List.Item>
          )}
        />
      </div>
    </div>
  )
}