import React, { useCallback } from "react";
import "../css/AppHeader.sass";
import { Input } from "antd";
import { useNavigateToHome } from "../utils/hooks/navigation/useNavigateToHome";

import { AppHeaderRight } from "./AppHeaderRight";
import { getPlayerFromFLUsername } from "../utils/server/serverAPI";
import { NavigationHooks } from "../utils/hooks/navigationHooks";
import logo from '../resources/images/logo.png';

const { Search } = Input;

export function AppHeader({ clearToken }) {
  const navigateToPlayerPage = NavigationHooks.useToPlayerPage();
  const navigateToHome = useNavigateToHome();

  const doSearch = useCallback((value) => {
    const username = value?.target?.value ?? value;
    async function doCallback() {
      const player = await getPlayerFromFLUsername(username);
      navigateToPlayerPage(player.playerId);
    }
    doCallback();
  }, [navigateToPlayerPage])

  return (
    <>
    <div className="app-header">
      <div className="logo" onClick={navigateToHome}>
        <img src={logo} alt="" />
      </div>
      <Search
        style={{ maxWidth: 400 }}
        className="search"
        placeholder="Search SafeWxrds Users"
        onSearch={doSearch}
        onPressEnter={doSearch}
      />
      <AppHeaderRight clearToken={clearToken} />
    </div>
    </>
  )
}