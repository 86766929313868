import React from "react";
import { MERCY_INITIAL_STATE, MercyGlobalState } from "./mercyReducer";


export const MercyContext = React.createContext<{
  state: MercyGlobalState;
  dispatch: React.Dispatch<any>
}>(null!);


// { state: MERCY_INITIAL_STATE, dispatch: () => {} }