import { Comment, PlayerId, CommentId, Player, FetlifeId, FetlifeUsername, FetlifeInfo } from "../../state/state";
import { ServerComment, ServerUser, ServerFetlife } from "../../state/server/serverState";

// Server App Transformers
export namespace SAT {
  export function transformComment(comment: ServerComment): Comment {
    return {
      writtenBy: comment.created_by as PlayerId,
      isAbout: comment.is_about as PlayerId,
      createdAt: new Date(comment.created_at),
      flaggedAt: comment.flagged_at ? new Date(comment.flagged_at ) : undefined,
      lastModified: new Date(comment.last_edited),
      comment: comment.comment,
      commentId: comment.comment_id as CommentId,
    };
  }

  export function transformPlayer(player: ServerUser): Player {
    return {
      playerId: player.player_id as PlayerId,
      fetlife: {
        id: player.fetlife?.fetlife_id as FetlifeId,
        username: player.fetlife?.fetlife_username as FetlifeUsername
      }
    };
  }

  export function transformFetlife(fetlife: ServerFetlife): FetlifeInfo {
    return {
      id: fetlife.fetlife_id as FetlifeId,
      username: fetlife.fetlife_username as FetlifeUsername
    }
  }

}