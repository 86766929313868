import { Alert, Button, Form, Input } from "antd";
import React, { useCallback, useState } from "react";
import { checkPassword, updatePassword } from "../../utils/server/serverAPI";
import type { FormInstance } from 'antd';
import { useUserId } from "../../utils/hooks/useUserId";


interface SubmitButtonProps {
  form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  return (
    <Button type="primary" htmlType="submit" disabled={!submittable} style={{ display: "flex", margin: "auto" }}>
      {children}
    </Button>
  );
};

type AlertType = "success" | "error" | "info" | "warning" | undefined;

export function ChangePasswordSection() {
  const userId = useUserId();
  const [alert, setAlert] = useState<{ message?: string; type: AlertType; }>({
    message: undefined,
    type: undefined
  });

  const handleFinish = useCallback(({ currentPassword, newPassword }) => {
    async function doIt() {
      const { status } = await updatePassword({ currentPassword, newPassword, playerId: userId })

      if (status === "success") {

        setAlert({
          message: "Password updated successfully.",
          type: "success" as AlertType,
        })
      } else if (status === "failure") {
        setAlert({
          message: "Unable to change password.",
          type: "error" as AlertType,
        })
      }
    }
    doIt()
  }, [userId, setAlert])


  const [form] = Form.useForm();
  return (
    <Form
      name="change-password-form"
      form={form}
      onFinish={handleFinish}
      labelCol={{ span: 12 }}
    >
      {alert.message != null && <Alert message={alert.message} type={alert.type} style={{ marginBottom: 10 }}/>}
      <Form.Item
        label="Current Password"
        name="currentPassword"
        validateDebounce={1000}
        rules={[
          { required: true, message: 'Please input your current password.' },
          () => ({
            async validator(_rule, value, _callback) {
              const isCorrectPassword = await checkPassword({
                playerId: userId,
                password: value
              })
              return isCorrectPassword
                ? Promise.resolve()
                : Promise.reject(new Error('Incorrect current password.'));
            },
          })]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="New Password"
        name="newPassword"
        validateDebounce={1000}
        rules={[{ required: true, message: 'Please input your new password.' }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Reenter New Password"
        name="newPasswordCheck"
        validateDebounce={1000}
        rules={[
          { required: true, message: 'Please input your new password again.' },
          ({ getFieldValue }) => ({
            validator(_rule, value, _callback) {
              const newPassword = getFieldValue("newPassword")

              if (!value || newPassword === value) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Passwords do not match.'));
            },
          })
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item>
        <SubmitButton form={form}>Submit</SubmitButton>
      </Form.Item>
    </Form>
  )
}