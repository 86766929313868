import React, { useCallback } from 'react';
import './css/App.sass';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { PlayerPage } from "./pages/player-page/PlayerPage";
import { MercyContext } from "./state/MercyContext";
import { LeaveCommentPage } from "./pages/comment-pages/LeaveCommentPage";
import { MERCY_INITIAL_STATE, mercyReducer } from "./state/mercyReducer";
import { MercyRoutes, getPlayerPageRouteForPlayer } from "./state/routes";
import { AppHeader } from "./app-header/AppHeader";
import { useInitializeState } from "./useInitializeState";
import { useToken } from "./utils/hooks/useToken";
import { initializeRepl } from "./initializeRepl";
import { UnauthedApp } from "./UnauthedApp";
import { ConfigProvider, theme } from 'antd';
import { User } from './state/state';
import { AdminTools } from './pages/admin/AdminTools';
import { UserSettingsPage } from './pages/user-settings-page/UserSettingsPage';
import { EditCommentPage } from './pages/comment-pages/EditCommentPage';
import { HomePage } from './pages/home-page/HomePage';

const oneDayMs = 1000 * 60 * 60 * 24; // 24 hours

function isValidToken(token?: string) {
  if (token == null) {
    return false
  }

  const now = new Date().getTime();
  const tokenExpiryTime = oneDayMs + parseInt(token);

  if (now > tokenExpiryTime) {
    return false
  }
  return true;
}

function App() {
  const [state, dispatch] = React.useReducer(mercyReducer, MERCY_INITIAL_STATE);

  const { token, userId, setToken } = useToken();
  const clearToken = useCallback(() => setToken(undefined), [setToken]);

  useInitializeState(dispatch, userId);
  initializeRepl(() => state);

  if (!isValidToken(token)) {
    return (
      <Router>
        <ConfigProvider
          theme={{
            algorithm: theme.darkAlgorithm,
            components: {
              Dropdown: {
                zIndexPopup: 1000000000
              },
            },
          }}
        >
          <UnauthedApp setToken={setToken} dispatch={dispatch} />
        </ConfigProvider>
      </Router>
    )
  }

  return (
    <MercyContext.Provider value={{ state, dispatch }}>
      <ConfigProvider
        theme={{
          algorithm: theme.darkAlgorithm,
        }}
      >
        <AppBody user={state.user} clearToken={clearToken} />
      </ConfigProvider>
    </MercyContext.Provider>
  );
}

function AppBody({ user, clearToken }: { user: User; clearToken: any }) {
  if (user == null) {
    return null
  }

  return (
    <div className="App">
      <Router>
        <AppHeader clearToken={clearToken}/>
        <div className='app-body'>
          <Routes>
            <Route path='/*' element={
              <Navigate to={getPlayerPageRouteForPlayer(user.playerId)} />
            } />
            <Route path={MercyRoutes.HOME} element={
              <HomePage />
            } />
            <Route path={MercyRoutes.ADMIN} element={
              <AdminRoutes user={user} />
            } />
            <Route path={MercyRoutes.PLAYER_PAGE} element={
              <PlayerPage />
            } />
            <Route path={MercyRoutes.LEAVE_COMMENT} element={
              <LeaveCommentPage />
            } />
            <Route path={MercyRoutes.EDIT_COMMENT} element={
              <EditCommentPage />
            } />
            <Route path={MercyRoutes.USER_SETTINGS} element={
              <UserSettingsPage />
            } />
          </Routes>
        </div>
      </Router>
    </div>
  )
}

function AdminRoutes( { user }: { user: User }) {
  if (user.playerId !== "admin") {
    return <></>;
  }

  return (
    <Routes>
      <Route path={"/"} element={<AdminTools />}/>
    </Routes>
  )
}

export default App;
