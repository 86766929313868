import { User } from "./state";

export interface MercyGlobalState {
  user?: User;
  shouldReloadComments: boolean;
  comments?: Comment[];
}

export const MERCY_INITIAL_STATE: MercyGlobalState = {
  user: undefined,
  shouldReloadComments: false,
};

export const mercyReducer = (state: MercyGlobalState, action: any) => {
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.payload
      }
    case "SET_SHOULD_RELOAD_COMMENTS":
      return {
        ...state,
        shouldReloadComments: action.payload
      }
    case "RESET_USER":
      return {
        ...state,
        user: undefined
      }
    case "SET_COMMENTS":
      return {
        ...state,
        comments: action.payload
      }
    default:
      return state;
  }
};