import { ServerComment } from "../../state/server/serverState";
import { BASE_URL, PlayerId, LoginCredentials, CommentId } from "../../state/state";
import { SAT } from "./SeverAppTransformers";

const API_URL = `${BASE_URL}/api`

export async function getPlayer(playerId: PlayerId) {
  const url = `${API_URL}/players/${playerId}`;

  const response = await fetch(url);
  const players = await response.json();

  if (players.length === 0) {
    console.warn(`Could not get player with id: ${playerId}`)
    return undefined;
  }

  const player = players[0];

  return player != null ? SAT.transformPlayer(player) : undefined;
}


export async function getAllPlayers() {
  const url = `${API_URL}/players/`;

  const response = await fetch(url);
  const players = await response.json();

  if (players.length === 0) {
    console.warn(`Could not get players.`)
    return undefined;
  }

  return players.map(SAT.transformPlayer);
}

export async function getCommentsAboutPlayer(query: string) {
  const url = `${API_URL}/comments/commentsAboutPlayer/${query}`;

  const comments = await fetch(url).then(response => response.json() as any as ServerComment[])

  return comments.map(SAT.transformComment)
}

export async function getCommentsByPlayer(query: string) {
  const url = `${API_URL}/comments/commentsByPlayer/${query}`;

  const comments = await fetch(url).then(response => response.json() as any as ServerComment[])

  return comments.map(SAT.transformComment)
}


export async function getPlayerFromFlId(id: string) {
  const url = `${API_URL}/players/fromFlId/${id}`

  const response = await fetch(url);

  if (response.status != 200) {
    console.warn(`Could not find play with Fetlife id ${id}`)
    return undefined
  }

  const player = await response.json();

  if (player.message != null) {
    return undefined;
  }
  return SAT.transformPlayer(player);
}

export async function getPlayerFromFLUsername(username: string) {
  const url = `${API_URL}/players/fromFlUsername/${username}`

  const response = await fetch(url);
  const maybePlayer = await response.json();

  return SAT.transformPlayer(maybePlayer);
}

export async function loginUser(credentials: LoginCredentials) {
  const url = `${API_URL}/login`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })

  if (response.status === 500) {
    return undefined;
  } else {
    return await response.json();
  }
}

export async function updatePassword(params: { currentPassword: string; newPassword: string; playerId: string; }) {
  const url = `${API_URL}/updatePassword`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })

  if (response.status === 500) {
    return undefined;
  } else {
    return await response.json();
  }
}

export async function checkPassword(params: { playerId: string; password: string; }) {
  const url = `${API_URL}/checkPassword`

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(params)
  })

  if (response.status === 500) {
    return undefined;
  } else {
    return await response.json();
  }
}


export async function getFetlifeInfoFromPlayerId(playerId: PlayerId) {
  const url = `${API_URL}/fetlife/${playerId}`

  try {
    const response = await fetch(url);
    const fetlifeUsers = await response.json();

    return SAT.transformFetlife(fetlifeUsers)
  } catch (e) {
    console.log(e)
  }
}

export async function createPlayer({ username, id, password
}: { username: string; id: string; password: string;}) {
  const url = `${API_URL}/players/create`;

  return await fetch(url, {
    method: "POST",
    body: JSON.stringify({
      fetlife_id: id,
      fetlife_username: username,
      password,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).then((d) => console.log("Successfully created player"))
  .catch(err => console.error(err));
}


 export function createNewComment(text: string, isAbout: string, createdBy: string) {
  const url = `${API_URL}/comments/create`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      created_by: createdBy,
      is_about: isAbout,
      comment: text,
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).catch(err => console.error(err));
}


export function deleteComment(commentId: CommentId) {
  const url = `${API_URL}/comments/delete/${commentId}`;

  return fetch(url, {
    method: "DELETE",
  }).catch(err => {
    console.error(`Could not delete comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}

export function deletePlayer(playerId: PlayerId) {
  const url = `${API_URL}/players/delete/${playerId}`;

  return fetch(url, {
    method: "DELETE",
  }).catch(err => {
    console.error(`Could not delete player with Id: ${playerId}\n Server Error: ${err}`);
  })
}

export function flagComment(commentId: CommentId) {
  const url = `${API_URL}/comments/flag/${commentId}`;

  return fetch(url, {
    method: "POST"
  }).catch(err => {
    console.error(`Could not flag comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}

export function unflagComment(commentId: CommentId) {
  const url = `${API_URL}/comments/unflag/${commentId}`;

  return fetch(url, {
    method: "POST"
  }).catch(err => {
    console.error(`Could not unflag comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}

export async function getComment(commentId: CommentId) {
  const url = `${API_URL}/comments/get/${commentId}`;

  try {
    const response = await fetch(url);

    if (response.status === 500) {
      return undefined;
    }
    const serverComments = await response.json() as ServerComment[];
    return SAT.transformComment(serverComments[0])
  } catch (e) {
    console.error(`Could not get comment with ID: ${commentId}\n Server Error: ${e}`);
  }
}

export function updateComment(commentId: CommentId, comment: string) {
  const url = `${API_URL}/comments/update`;

  return fetch(url, {
    method: "POST",
    body: JSON.stringify({
      comment: comment,
      commentId: commentId
    }),
    headers: {
      'Content-Type': 'application/json'
    },
  }).catch(err => {
    console.error(`Could not update comment with ID: ${commentId}\n Server Error: ${err}`);
  })
}


export async function getCommentCountsByPlayer() {
  const url = `${API_URL}/comments/countsbyPlayer`;

  try {
    const commentCounts = await fetch(url)
    return await commentCounts.json()
  } catch (err) {
    console.error(`Could not get player comment Counts. Server Error: ${err}`);
  }
}
