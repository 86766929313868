import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { MercyContext } from "../../state/MercyContext";
import { PlayerId } from "../../state/state";

export function getPlayerIdFromUrl(location: any, state?: any) {

  const urlParts = location.pathname.split('/');

  switch (urlParts[1]) {
    case "player":
      return urlParts[2] as PlayerId;
    case "leaveComment":
      return urlParts[2] as PlayerId;
    default:
      return state?.user.playerId as PlayerId;
  }
}


export function useGetIdFromPlayerUrl(): PlayerId {
  const location = useLocation();
  const { state } = useContext(MercyContext);

  return getPlayerIdFromUrl(location, state)
}