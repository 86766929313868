import { MercyGlobalState } from "./state/mercyReducer";
import { getPlayerIdFromUrl } from "./utils/hooks/useGetIdFromPlayerUrl";
import { getCommentsAboutPlayer, getCommentsByPlayer } from "./utils/server/serverAPI";

export function initializeRepl(getState: () => MercyGlobalState) {
  (window as any).repl = {
    getState: () => getState(),
    getComments: async () => {
      const playerId = getPlayerIdFromUrl(window.location)

      const commentsAbout = await getCommentsAboutPlayer(playerId);
      const commentsBy = await getCommentsByPlayer(playerId);

      console.log({commentsAbout, commentsBy })
      return { commentsAbout, commentsBy }
    }
  }
}