import React, { useContext, useCallback } from "react";
import { useNavigateToLoginPage } from "../utils/hooks/navigation/useNavigateToLoginPage";
import { useNavigateToUserSettingsPage } from "../utils/hooks/navigation/useNavigateToUserSettingsPage"
import { useGetUserFetlifeUsername } from "../utils/hooks/state-getters/useGetUserFetlifeUsername";
import { MercyContext } from "../state/MercyContext";
import { DownCircleOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { NavigationHooks } from "../utils/hooks/navigationHooks";

export function AppHeaderRight({ clearToken }) {
  const { dispatch } = useContext(MercyContext);
  const username = useGetUserFetlifeUsername();
  const navigateToLoginPage = useNavigateToLoginPage();
  const navigateToUserSettingsPage = useNavigateToUserSettingsPage();
  const navigateToUserPage = NavigationHooks.useToUserPage();

  const handleLogout = useCallback(() => {
    clearToken();
    dispatch({ type: "RESET_USER" })
    navigateToLoginPage();
  }, [dispatch, clearToken, navigateToLoginPage]);

  const handleGoToSettings = useCallback(() => {
    navigateToUserSettingsPage()
  }, [navigateToUserSettingsPage])

  const handleClickUsername = useCallback(() => {
    navigateToUserPage();
  }, [navigateToUserPage])

  const items = [{
    key: "settings",
    label: "Settings",
    onClick: handleGoToSettings
  }, {
    key: "logout",
    label: "Logout",
    onClick: handleLogout,
  }]

  return (
    <div className="app-header-right">
      <div style={{ display: "flex" }}>
        <div style={{paddingRight: 5}} onClick={handleClickUsername}>{username}</div>
        <Dropdown menu={{ items }} overlayClassName="user-menu" overlayStyle={{ marginTop: 18}} >
          <DownCircleOutlined />
        </Dropdown>
      </div>
    </div>
  )
}
