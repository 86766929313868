import React, { useCallback, useEffect, useState } from 'react';
import { Player } from '../../state/state';
import { Divider, List } from 'antd';
import { getAllPlayers, getCommentCountsByPlayer } from '../../utils/server/serverAPI';
import { PlayerTag } from '../../common/PlayerTag';
import { filter } from 'lodash';
import Search from 'antd/es/input/Search';

export function HomePage() {
  const [players, setPlayers] = useState<Player[]>([])
  const [search, setSearch] = useState("");
  const [commentCounts, setCommentCounts] = useState({
    isAbout: {},
    createdBy: {}
  });

  useEffect(() => {
    async function loadPlayers() {
      const players = await getAllPlayers();
      const noAdmin = filter(players, d => d.playerId !== "admin")
      setPlayers(noAdmin)
    }
    loadPlayers();
  }, [setPlayers])

  useEffect(() => {
    async function loadCommentCounts() {
      const counts = await getCommentCountsByPlayer();
      setCommentCounts(counts)
    }
    loadCommentCounts();
  }, [setCommentCounts])

  const onSearch = useCallback((evt) => {
    setSearch(evt.target.value)
  }, [setSearch]);

  const filteredPlayers = filter(players, d => d.fetlife.username.toLowerCase().includes(search.toLowerCase()))

  return (
    <div>
      <div className="search-input" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <div style={{ display: "flex", flexDirection: "row" , alignItems: "center"}}>
          <div style={{ paddingRight: 10, fontSize: 16, fontWeight: 700 }}>Don't know who's on the site? Search for a user here.</div>
          <Search
            placeholder="Search for a user"
            allowClear
            onChange={onSearch}
            style={{ width: 250 }}
          />
        </div>
        <Divider />
        <List
          dataSource={filteredPlayers}
          style={{ minWidth: 500 }}
          renderItem={({ playerId }) => (
            <List.Item key={playerId} style={{ maxWidth: 550 }}>
              <List.Item.Meta title={<PlayerTag playerId={playerId} />}/>
              <div style={{ paddingRight: 30 }}>{commentCounts.createdBy[playerId] ?? 0} comments written</div>
              <div>{commentCounts.isAbout[playerId] ?? 0} comments about</div>
            </List.Item>
          )}
        />
      </div>
    </div>
  );
}