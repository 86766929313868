import React, { useCallback, useState } from "react"
import { Divider } from "antd";
import { AdminCreatePlayer } from "./AdminCreatePlayer";
import { AdminListPlayers } from "./AdminListPlayers";
import Title from "antd/es/typography/Title";
import { Player } from "../../state/state";
import { getAllPlayers } from "../../utils/server/serverAPI";

export function AdminTools() {
  const [players, setPlayers] = useState<Player[]>([])

  const loadPlayers = useCallback(() => {
    async function doIt() {
      const allPlayers = await getAllPlayers()
      setPlayers(allPlayers);
    }
    doIt();
  }, [setPlayers])

  return (
    <div style={{ width: 800, display: "flex",
      flexDirection: "column",
      alignItems: "center"}}>
      <Title>Create Player</Title>
      <AdminCreatePlayer loadPlayers={loadPlayers} />
      <Divider />
      <Title>List of Player</Title>
      <AdminListPlayers loadPlayers={loadPlayers} players={players} />
    </div>
  )
}