import React, { useCallback, useEffect, useState } from "react";
import { PlayerId } from "../state/state";
import { getFetlifeInfoFromPlayerId } from "../utils/server/serverAPI";
import { NavigationHooks } from "../utils/hooks/navigationHooks";

export function PlayerTag({ playerId }: { playerId : PlayerId }) {
  const [username, setUsername] = useState("")

  useEffect(() => {
    async function getFetlife() {
      const fetlifeInfo = await getFetlifeInfoFromPlayerId(playerId)
      setUsername(fetlifeInfo.username);
    }
    getFetlife()
  }, [setUsername, playerId])

  const navigateToPlayerPage = NavigationHooks.useToPlayerPage();

  const handleClick = useCallback(() => {
    navigateToPlayerPage(playerId);
  }, [navigateToPlayerPage, playerId])

  return <a onClick={handleClick} style={{fontWeight: 600}}>{username}</a>;
}