import React, { useCallback, useContext, useEffect, useState } from 'react';
import { CommentCard } from "./CommentCard";
import { Comment } from "../../../state/state";
import "../../../css/CommentsSection.sass";
import { useGetIdFromPlayerUrl } from '../../../utils/hooks/useGetIdFromPlayerUrl';
import { getCommentsAboutPlayer, getCommentsByPlayer } from '../../../utils/server/serverAPI';
import { sortBy } from 'lodash';
import { MercyContext } from '../../../state/MercyContext';


export function CommentsSection({ commentType }: { commentType: string; }) {
  const { state, dispatch } = useContext(MercyContext);
  const [comments, setComments] = useState<Comment[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const loadComments = commentType === "ABOUT" ? getCommentsAboutPlayer : getCommentsByPlayer;

  const playerId = useGetIdFromPlayerUrl();

  const reloadComments = useCallback(() => {
    setIsLoading(true);
    async function doIt() {
      const comments = await loadComments(playerId)

      const sortedComments = sortBy(comments, d => d.lastModified.getTime()).reverse()

      setComments(sortedComments);
      setIsLoading(false);
    }
    doIt()
  }, [playerId, setComments, setIsLoading, loadComments]);

  useEffect(reloadComments, [reloadComments])
  useEffect(() => {
    if (!state.shouldReloadComments) {
      return
    }
    reloadComments()
    dispatch({ type: "SET_SHOULD_RELOAD_COMMENTS", payload: false })
  }, [state.shouldReloadComments, reloadComments, dispatch])

  if (comments.length === 0 && isLoading === false) {
    return <div className="comments-section-no-comments"><div>No comments yet. :( </div><div>Be the first to leave a comment!</div></div>
  }

  return (
    <div className={"comments-section"}>{comments.map((comment, i) => (
      <CommentCard key={i} comment={comment} reloadComments={reloadComments} />
    ))}</div>
  )
}