import React, { useContext, useCallback } from "react";
import { MercyContext } from "../../state/MercyContext";
import { createNewComment } from "../../utils/server/serverAPI";
import "../../css/LeaveCommentPage.sass";
import { useGetIdFromPlayerUrl } from "../../utils/hooks/useGetIdFromPlayerUrl";
import { BaseCommentPage } from "./BaseCommentPage";
import { PlayerTag } from "../../common/PlayerTag";


export function LeaveCommentPage() {
  const { state } = useContext(MercyContext);
  const playerId = useGetIdFromPlayerUrl();

  const handleSubmit = useCallback((text) => {
    createNewComment(text, playerId, state.user.playerId);
    // TODO - error handling of bad comment
  }, [playerId, state])

  return (
    <BaseCommentPage
      onSubmit={handleSubmit}
      title={<div>Describe your experience playing with <PlayerTag playerId={playerId} /></div>}
      playerId={playerId}
    />
  )
}