import { useLocation } from "react-router-dom";
import { CommentId } from "../../state/state";


export function getCommentIdFromUrl(location: any) {
  const urlParts = location.pathname.split('/');

  switch (urlParts[1]) {
    case "editComment":
      return urlParts[2] as CommentId;
    default:
      return "INVALID_COMMENT_ID" as CommentId;
  }
}


export function useGetCommentIdFromUrl(): CommentId {
  const location = useLocation();
  return getCommentIdFromUrl(location);
}