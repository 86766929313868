import { useCallback } from "react";
import { useNavigate } from "react-router";
import { getPlayerPageRouteForPlayer, MercyRoutes } from "../../state/routes";
import { CommentId, PlayerId } from "../../state/state";
import { useGetIdFromPlayerUrl } from "./useGetIdFromPlayerUrl";
import { useUserId } from "./useUserId";


export namespace NavigationHooks {
  export function useToLeaveCommentPage() {
    const navigate = useNavigate();

    return useCallback((playerId: PlayerId) => navigate(MercyRoutes.LEAVE_COMMENT.replace(":player_id", playerId)), [navigate]);
  }

  export function useToEditCommentPage() {
    const navigate = useNavigate();

    return useCallback((commentId: CommentId) => navigate(MercyRoutes.EDIT_COMMENT.replace(":comment_id", commentId)), [navigate]);
  }

  export function useToPlayerPage() {
    const navigate = useNavigate();

    return useCallback((playerId: PlayerId) => navigate(getPlayerPageRouteForPlayer(playerId)), [navigate]);
  }

  export function useToCurrentPlayerPage() {
    const playerId = useGetIdFromPlayerUrl();
    const toPlayerPage = useToPlayerPage();

    return useCallback(() => toPlayerPage(playerId), [toPlayerPage, playerId])
  }

  export function useToUserPage() {
    const userId = useUserId();
    const toPlayerPage = useToPlayerPage();

    return useCallback(() => toPlayerPage(userId), [toPlayerPage, userId])
  }
}