
import { MoreOutlined } from "@ant-design/icons";
import React, { useCallback } from "react";
import { Comment } from "../../../state/state";
import { useUserId } from "../../../utils/hooks/useUserId";
import { deleteComment, flagComment, unflagComment } from "../../../utils/server/serverAPI";
import { Dropdown } from "antd";
import { compact } from "lodash";
import { NavigationHooks } from "../../../utils/hooks/navigationHooks";


export function CommentCardOptionsMenu({ comment, reloadComments }: { comment: Comment; reloadComments: any; }) {
  const userId = useUserId();
  const navigateToEditCommentPage = NavigationHooks.useToEditCommentPage();

  const handleClickDelete = useCallback(() => {
    deleteComment(comment.commentId).then(d => {
      reloadComments();
    })
  }, [comment.commentId, reloadComments])

  const handleClickEdit = useCallback(() => {
    navigateToEditCommentPage(comment.commentId);
  }, [navigateToEditCommentPage, comment.commentId])

  const toggleCommentFlag = useCallback(() => {
    async function flag() {
      await comment.flaggedAt == null ? flagComment(comment.commentId) : unflagComment(comment.commentId);
      reloadComments();
    }
    flag()
  }, [comment.flaggedAt, comment.commentId, reloadComments])

  const menuItems = compact([
    userId === comment.writtenBy && {
      key: 'edit',
      label: "Edit Comment",
      onClick: handleClickEdit,
    },
    userId === comment.writtenBy && {
      key: 'delete',
      label: "Delete Comment",
      onClick: handleClickDelete,
    },
    userId === comment.isAbout && {
      key: 'flag',
      label: comment.flaggedAt == null ? "Flag comment as inaccurate" : "Unflag comment",
      onClick: toggleCommentFlag,
    },
  ]);

  if (menuItems.length === 0) {
    return null;
  }

  return (
    <Dropdown
      menu={{ items: menuItems }}
      className="comment-card-more-options"
    >
      <MoreOutlined />
    </Dropdown>
  );
}