import { useEffect } from 'react';
import './css/App.sass';
import { getPlayer } from './utils/server/serverAPI';
import { UserId } from './state/state';

export function useInitializeState(dispatch: (a: any) => any, userId?: UserId) {
  useEffect(() => {
    async function initializeUser() {
      if (userId == null) {
        return;
      }
      const user = await getPlayer(userId);
      if (user != null) {
        dispatch({ type: "SET_USER", payload: user })
      }
    }
    initializeUser();
  }, [dispatch, userId])
}